<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import { isMobile } from '@/utils/helpers.js';
import { useStore } from '@/stores/store.js';

import Button from '@/components/common/Button.vue';
import Icon from '@/components/common/Icon.vue';

const logoUrl = new URL('/../public/images/nsoft88-logo.png', import.meta.url).href;

const store = useStore();
const { punterWallet, currency, punterData, punterLoggedIn } = storeToRefs(store);

const balance = computed(() => {
  const activeCurrencyBalance = (+punterWallet.value.activeCurrencyBalance).toFixed(2);
  if (isMobile) return activeCurrencyBalance;

  return `${activeCurrencyBalance} ${currency.value}`;
});

function handleDepositClick() {
  // @ToDo: Replace with integrator.navigateTo on staging
  window.location.href = store.config.header.depositExternalUrl;
}
</script>

<template>
  <div class="header__wrapper">
    <div :class="['header', { 'header--mobile': isMobile }]">
      <img
        :src="logoUrl"
        alt="logo" />

      <div
        v-if="punterLoggedIn"
        :class="[
          'header__account',
          { 'header__account--mobile': isMobile, 'header__account--desktop': !isMobile },
        ]">
        <div class="account__details">
          <span
            v-if="!isMobile"
            class="account__name">
            {{ punterData.nickname }}
          </span>
          <span v-else>{{ currency }}</span>

          <span class="account__balance">
            {{ balance }}
          </span>
        </div>

        <Button
          v-if="store.config.header.depositExternalUrl"
          class="account__deposit"
          @click="handleDepositClick">
          <Icon icon="n-i-deposit" />
          <span v-if="!isMobile">{{ store.getTranslation('general_deposit') }}</span>
        </Button>
      </div>
    </div>
  </div>
</template>

<style>
:root {
  --header-deposit-btn-text-color: #000;
  --header-balance-bg-color: rgba(0, 0, 0, 0.1);
}
</style>

<style scoped lang="scss">
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0.5rem 0.75rem 1rem;
  background-color: var(--brand);
  position: sticky;
  @include z-index(header);

  &--mobile {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    height: var(--mob-app-header-height);
  }

  img {
    height: 2rem;
  }

  .account {
    &__details {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      color: var(--text-delta);
    }

    &__balance {
      font-weight: 700;
    }

    &__deposit {
      background: var(--contrast-color-alpha);
      color: var(--header-deposit-btn-text-color);
      border-radius: 0.25rem;
      gap: 0.5rem;
      font-weight: 500;
    }
  }

  &__account {
    display: flex;

    &--desktop {
      padding: 0.25rem 0.5rem 0.25rem 1rem;
      background: var(--header-balance-bg-color);
      border-radius: 0.25rem;
      gap: 1rem;
    }

    &--mobile {
      gap: 0.25rem;

      .account__details {
        width: 5.9rem;
        background: var(--header-balance-bg-color);
        border-radius: 0.25rem;
        padding: 0.3rem 0.5rem;
      }

      .account__deposit {
        width: 2.5rem;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
</style>
