import axios from 'axios';

import { constructQueryFromObject } from './api-helpers';

import { isMobile } from '../services/helpers';
import { getItemFromStorage } from '../services/storage';
import { constructClientIds } from '../utils/helpers';

const aioSettings = getItemFromStorage('aio_settings');

const { VITE_OFFER_API, VITE_GAME_IDS } = import.meta.env;

const baseUrl = aioSettings?.baseUrl ?? VITE_OFFER_API;
const gameIds = aioSettings?.gameIds ?? VITE_GAME_IDS;

const defaultNumberOfEvents = isMobile ? 10 : 20;
// to test indonesian view
const numberOfMarketsPerSport = 6; // 5 as default

let abortController = null;
let eventOfferAbortController = null;

const http = axios.create({
  baseURL: `${baseUrl}`,
});

/**
 * @description returns promise of offer data
 * @param {{}} params
 * @returns {Promise}
 */
export function getOffer(params = {}, isLive) {
  const queryObj = {
    expectedNumberOfEvents: params.expectedNumberOfEvents ?? defaultNumberOfEvents,
    numberOfMarketsPerSport: params.numberOfMarketsPerSport ?? numberOfMarketsPerSport,
    ...params,
  };

  if (params.startsAtFrom) {
    queryObj.startsAtFrom = dayjs(params.startsAtFrom).utc().format('YYYY-MM-DDTHH:mm:ss');
  }

  if (params.startsAtTo) {
    queryObj.startsAtTo = dayjs(params.startsAtTo).utc().format('YYYY-MM-DDTHH:mm:ss');
  }

  return http({
    method: 'GET',
    url: `/tenants/${window.tenantUuid}/games/${isLive ? '2' : params.type === 2 ? '1' : gameIds}/languages/${
      window.languageCode
    }/offer/cursors${constructQueryFromObject(queryObj)}`,
  });
}

/**
 * @description returns promise of offer data based on cursor id
 * @param {string} cursor
 * @param {number} expectedNumberOfEvents
 * @returns {Promise}
 */
export async function getCursorOffer(
  cursor,
  isLive,
  expectedNumberOfEvents = defaultNumberOfEvents,
  params = {},
  forcePrematch = false,
) {
  const queryObj = {
    ...params,
    expectedNumberOfEvents: expectedNumberOfEvents,
    numberOfMarketsPerSport,
    clientIds: constructClientIds(),
  };

  if (abortController) abortController.abort();

  abortController = new AbortController();

  return http({
    method: 'GET',
    url: `/tenants/${window.tenantUuid}/games/${isLive ? '2' : forcePrematch ? '1' : gameIds}/languages/${
      window.languageCode
    }/offer/cursors/${cursor}${constructQueryFromObject(queryObj)}`,
    signal: abortController.signal,
  });
}

/**
 * @description returns promise of favourites offer data
 * @param {{}} params
 * @returns {Promise}
 */
export function getFavouritesOffer(params = {}, isLive) {
  const queryObj = {
    ...params,
  };

  if (params.startsAtFrom) {
    queryObj.startsAtFrom = dayjs(params.startsAtFrom).utc().format('YYYY-MM-DDTHH:mm:ss');
  }

  if (params.startsAtTo) {
    queryObj.startsAtTo = dayjs(params.startsAtTo).utc().format('YYYY-MM-DDTHH:mm:ss');
  }

  return http({
    method: 'GET',
    url: `/tenants/${window.tenantUuid}/games/${isLive ? '2' : gameIds}/languages/${
      window.languageCode
    }/favorites/anonymous/cursors${constructQueryFromObject(queryObj)}`,
  });
}

/**
 * @description returns promise of favourites offer data based on cursor id
 * @param {string} cursor
 * @param {number} expectedNumberOfEvents
 * @returns {Promise}
 */
export async function getFavouritesCursorOffer(
  cursor,
  isLive,
  expectedNumberOfEvents = defaultNumberOfEvents,
  params = {},
) {
  const queryObj = {
    ...params,
    expectedNumberOfEvents,
    numberOfMarketsPerSport,
  };

  if (abortController) abortController.abort();

  abortController = new AbortController();

  return http({
    method: 'GET',
    url: `/tenants/${window.tenantUuid}/games/${isLive ? '2' : gameIds}/languages/${
      window.languageCode
    }/favorites/anonymous/cursors/${cursor}${constructQueryFromObject(queryObj)}`,
    signal: abortController.signal,
  });
}

/**
 * @description fetch event's market data
 * @param {number} eventId
 * @param {boolean} excluedMarketMetadata
 * @returns {Promise}
 */
export async function getEvents(events, gameIds = '1,2', params = {}) {
  // TODO: add option to remember sport so we don't load markets again
  if (eventOfferAbortController) eventOfferAbortController.abort();

  eventOfferAbortController = new AbortController();

  return await http({
    method: 'GET',
    url: `/tenants/${window.tenantUuid}/games/${gameIds}/languages/${window.languageCode}/offer/events/${events.join(',')}${constructQueryFromObject(params)}`,
    signal: eventOfferAbortController.signal,
  });
}

export async function exchangeOfferIds({ events, idTypes }, pGameIds = null) {
  return await http({
    method: 'GET',
    url: `/tenants/${window.tenantUuid}/games/${pGameIds ?? gameIds}/languages/${window.languageCode}/offer-id/exchange`,
    params: {
      events,
      idTypes,
    },
  });
}

/**
 * @description returns promise of event counts (used for fitlers) based on cursor id
 * @param {string} cursor
 * @returns {Promise}
 */
export async function getCursorOfferStats(params = {}, gameId, cursorId = '') {
  const cursor = cursorId ? `/${cursorId}` : '';

  const queryObj = { ...params };
  if (params.startsAtFrom) {
    queryObj.startsAtFrom = dayjs(params.startsAtFrom).utc().format('YYYY-MM-DDTHH:mm:ss');
  }

  if (params.startsAtTo) {
    queryObj.startsAtTo = dayjs(params.startsAtTo).utc().format('YYYY-MM-DDTHH:mm:ss');
  }

  return await http({
    method: 'GET',
    url: `/tenants/${window.tenantUuid}/games/${gameId}/languages/${
      window.languageCode
    }/offer-stats/event-counts/cursors${cursor}${constructQueryFromObject(queryObj)}`,
  });
}

/**
 * @description returns promise of event counts of promoted offfer (used for filters) based on cursor id
 * @param {string} cursor
 * @returns {Promise}
 */
export async function getCursorPromoOfferStats(params = {}, gameId, cursorId = '') {
  const cursor = cursorId ? `/${cursorId}` : '';

  const queryObj = { ...params };
  if (params.startsAtFrom) {
    queryObj.startsAtFrom = dayjs(params.startsAtFrom).utc().format('YYYY-MM-DDTHH:mm:ss');
  }

  if (params.startsAtTo) {
    queryObj.startsAtTo = dayjs(params.startsAtTo).utc().format('YYYY-MM-DDTHH:mm:ss');
  }

  return await http({
    method: 'GET',
    url: `/tenants/${window.tenantUuid}/games/${gameId}/languages/${
      window.languageCode
    }/promoted-offer-stats/event-counts/cursors${cursor}${constructQueryFromObject(queryObj)}`,
  });
}

/**
 * @description returns promise of markets specifier types
 * @returns {Promise}
 */
export async function getMarketSpecifiers() {
  return await http({
    method: 'GET',
    url: `/tenants/${window.tenantUuid}/games/1,2/languages/${window.languageCode}/metadata/markets/specifier-types`,
  });
}

export async function getRecommendedMatches(params = {}, game) {
  return await http({
    method: 'GET',
    url: `/tenants/${window.tenantUuid}/games/${game}/languages/${
      window.languageCode
    }/offer/recommendations/events/popular${constructQueryFromObject(params)}`,
  });
}

/**
 * @description returns promise of promoted offer data
 * @param {{}} params
 * @returns {Promise}
 */
export async function getPromotedOffer(params = {}, game) {
  const queryObj = {
    expectedNumberOfEvents: params.expectedNumberOfEvents ?? defaultNumberOfEvents,
    numberOfMarketsPerSport: params.numberOfMarketsPerSport ?? numberOfMarketsPerSport,
    ...params,
  };

  if (params.startsAtFrom) {
    queryObj.startsAtFrom = dayjs(params.startsAtFrom).utc().format('YYYY-MM-DDTHH:mm:ss');
  }

  if (params.startsAtTo) {
    queryObj.startsAtTo = dayjs(params.startsAtTo).utc().format('YYYY-MM-DDTHH:mm:ss');
  }

  return http({
    method: 'GET',
    url: `/tenants/${window.tenantUuid}/games/${game ? '2' : '1'}/languages/${
      window.languageCode
    }/promoted-offer/cursors${constructQueryFromObject(queryObj)}`,
  });
}

/**
 * @description returns promise of promo offer data based on cursor id
 * @param {string} cursor
 * @param {number} expectedNumberOfEvents
 * @returns {Promise}
 */
export async function getCursorPromoOffer(cursor, isLive, params = {}) {
  if (abortController) abortController.abort();

  abortController = new AbortController();

  return http({
    method: 'GET',
    url: `/tenants/${window.tenantUuid}/games/${isLive ? '2' : '1'}/languages/${
      window.languageCode
    }/promoted-offer/cursors/${cursor}${constructQueryFromObject(params)}`,
    signal: abortController.signal,
  });
}
