import { io } from 'socket.io-client';
import { useBetslipStore } from '../../stores/BetslipStore';
import { getItemFromStorage } from '../storage';

let sio = null;

const aioSettings = getItemFromStorage('aio_settings');

const { VITE_CALCULATION_SOCKET, VITE_GAME_IDS } = import.meta.env;

const calculationSocket = aioSettings?.calculationUrl ?? VITE_CALCULATION_SOCKET;
const gameIds = aioSettings?.gameIds ?? VITE_GAME_IDS;

export function establishCalculationSocketConnection() {
  const socket = io(calculationSocket, {
    query: {
      tenantId: window.tenantUuid,
      gameIds: gameIds,
    },
    transports: ['websocket'],
  });
  sio = socket;

  socket.emit('connection');

  const betslipStore = useBetslipStore();

  socket.on('calculation-result', (message) => {
    betslipStore.updateBetCalculationData(message.data);
  });
}

export function sendCalculationWSMessage(message, payload) {
  if (payload.data.betslip?.bets?.[0]?.selections?.length)
    sio.emit(message, { ...payload, token: punterToken ?? '' });
}
