import { computed } from 'vue';
import { Supplemental } from '@nsftx/sports-client-aio-display-name-sdk';

import { useStore } from '@/stores/store.js';
import { isMobile } from '@/utils/helpers.js';

export default function useSupplemental(origin) {
  const supplemental = new Supplemental();

  const store = useStore();

  const supplementalToUse = computed(() => {
    const { supplemental } = store.config || {};
    const applicationView = isMobile ? 'mobile' : 'desktop';

    return (
      supplemental?.[store.offerView]?.[applicationView]?.[origin] ??
      supplemental?.[applicationView]?.[origin] ??
      null
    );
  });

  function getCompetitorSupplementalTeamName(competitor) {
    return supplemental.getCompetitorSupplementalTeamName(supplementalToUse.value, competitor);
  }

  function getCompetitorSupplementalPlayerName(competitor) {
    return supplemental.getCompetitorSupplementalPlayerName(supplementalToUse.value, competitor);
  }

  function getCompetitorSupplementalName(competitor) {
    return supplemental.getCompetitorSupplementalName(supplementalToUse.value, competitor);
  }

  function getMarketSupplementalName(market, event) {
    return supplemental.getMarketSupplementalName(supplementalToUse.value, market, event);
  }

  function getMarketOutcomeSupplementalName(outcome, event, market) {
    return supplemental.getMarketOutcomeSupplementalName(
      supplementalToUse.value,
      outcome,
      event,
      market,
    );
  }

  function getSupplemental(data, fallback) {
    return supplemental.getSupplemental(supplementalToUse.value, data, fallback);
  }

  return {
    getSupplemental,

    // competitor
    getCompetitorSupplementalTeamName,
    getCompetitorSupplementalPlayerName,
    getCompetitorSupplementalName,

    // market
    getMarketSupplementalName,
    getMarketOutcomeSupplementalName,
  };
}
