import axios from 'axios';
import { getItemFromStorage } from '../services/storage';

const aioSettings = getItemFromStorage('aio_settings');

const { VITE_BET_LIST } = import.meta.env;

const baseUrl = aioSettings?.betListUrl ?? VITE_BET_LIST;

const http = axios.create({
  baseURL: baseUrl,
});

export async function getBetList(data) {
  try {
    const response = await http({
      method: 'GET',
      url: `/tenants/${window.tenantUuid}/players/${window.punter.id}/last`,
      params: data,
    });

    return response.data;
  } catch (e) {
    console.log(e);
  }
}

export async function getBetDetailsByExternalBetId(betId) {
  try {
    const response = await http({
      method: 'GET',
      url: `/tenants/${window.tenantUuid}/bets/${betId}`,
      params: {},
    });

    return response.data;
  } catch (e) {
    console.log(e);
  }
}

export async function getBetDetailsByRegularBetId(betId) {
  try {
    const response = await http({
      method: 'GET',
      url: `/tenants/${window.tenantUuid}/bets/regular-hash/${betId}`,
    });

    return response.data;
  } catch (e) {
    console.log(e);
  }
}
