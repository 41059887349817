import { defineStore } from 'pinia';
import { ref, computed, shallowRef } from 'vue';
import { isEmpty } from 'lodash-es';
import { useRouter } from 'vue-router';

import { useStore } from '@/stores/store';
import { placeBet, checkBetslip, acceptAlternativeStakeBet } from '@/api/betslip';
import { getBetBuilderCalculations } from '@/api/custom-bet';
import { sendCalculationWSMessage } from '@/services/sockets/calculationSocket';
import { formatBerryBetPlacement } from '@/services/formatters/berryFormatter';
import { formatBetForBetslipPlacement } from '@/services/formatters/betslipFormatter';
import { formatOdd } from '@/utils/helpers';
import { getItemFromStorage, setItemToStorage } from '@/services/storage';

import BetslipWorker from '@/services/betslip-worker/index?worker';
const betslipWorker = new BetslipWorker();

export const useBetslipStore = defineStore('BetslipStore', () => {
  const router = useRouter();

  const store = useStore();

  const selectionsData = ref({});
  const selectionsList = ref([]);

  const pascalTriagle = shallowRef([]);

  const selectionsByEvent = computed(() =>
    selectionsList.value.map((selection) => {
      const [event] = selection.split('/');
      return event;
    }),
  );

  const calculationWinnings = ref(null);
  const stake = ref(null);

  const limits = ref({});
  const breachedLimits = ref([]);

  const exchangedIdsCache = ref({});

  const ticketOptions = getItemFromStorage('ticketOptions');

  const selectedTicketType = ref(ticketOptions?.type ?? 'combo');
  const selectedBetType = ref(selectTicketType.value);

  const systems = ref(ticketOptions?.systems ?? {});

  const alternativeStakeData = ref({});

  const betProcessing = ref(false);
  function setBetProcessing(value) {
    betProcessing.value = value;
  }

  const betBuilderBetsBeingPlayed = ref(false);

  const pendingPlacedBets = ref({});

  /**
   * Computed
   */

  const activeSelectionsCount = computed(
    () =>
      Object.values(selectionsData.value).filter(
        (selection) =>
          [...selection.markets]?.filter(([, { active }]) => active).length > 1 || selection.active,
      ).length,
  );

  const availableSystems = computed(() =>
    Object.values(selectionsData.value)?.filter((selection) => {
      return !selection.banker && selection.active;
    }),
  );

  const totalSelectionsOdds = computed(() => {
    const totalOdds = Object.values(selectionsData.value)
      .reduce(
        (acc, selection) => {
          return [...selection.markets].filter(([, { active }]) => active).length > 1 ||
            selection.active
            ? acc * +formatOdd(selection.odds, 'decimal')
            : acc;
        },
        activeSelectionsCount.value ? 1 : 0,
      )
      .toFixed(2);

    return formatOdd(+totalOdds, store.oddFormat, 1);
  });

  const splitStakePerSelection = computed(() =>
    parseFloat((parseFloat(stake.value) / activeSelectionsCount.value || 1).toFixed(2)),
  );

  const totalSelectionsStake = computed(() => {
    if (!activeSelectionsCount.value) return 0;

    const totalStake = Object.values(selectionsData.value)
      .reduce((acc, selection) => acc + parseFloat(selection.stake || 0), 0)
      .toFixed(2);

    return totalStake;
  });

  const selectionHasSingleStakeSet = computed(() =>
    selectionsList?.value?.find((id) => selectionsData?.value[id]?.stake),
  );

  const totalSystemCombinations = computed(() =>
    Object.values(systems.value).reduce((acc, n) => acc + n.combinations, 0),
  );

  const defaultStake = computed(() => {
    const stake = store.config.betslip.stake[window.currency] ?? store.config.betslip.stake.default;
    return stake ? parseFloat(stake).toFixed(2) : '2.00';
  });

  /**
   * Actions
   */

  function removeSelection(id) {
    if (!selectionsData.value[id]) return;

    store.setActiveTicketTab('betslip');

    delete selectionsData.value[id];

    selectionsList.value = selectionsList.value.filter((selection) => selection !== id);

    clearSystems();

    if (!selectionsList.value.length) selectedTicketType.value = 'combo';
    alternativeStakeData.value.enabled = false;
  }

  function addSelection(id, data = {}) {
    store.setActiveTicketTab('betslip');
    selectionsData.value[id] = data;

    if (!selectionsList.value.some((selection) => selection === id)) {
      selectionsList.value.push(id);
    }

    alternativeStakeData.value.enabled = false;
  }

  async function toggleSelection(id, outcomeId, marketId) {
    if (!selectionsData.value[id]) return;

    if (selectionsData.value[id].type === 'bet_builder') {
      if (selectionsData.value[id].markets?.get(`${marketId}/${outcomeId}`)) {
        selectionsData.value[id].markets.get(`${marketId}/${outcomeId}`).active =
          !selectionsData.value[id].markets.get(`${marketId}/${outcomeId}`).active;
      }
    } else {
      selectionsData.value[id].active = !selectionsData.value[id].active;
    }

    clearSystems();

    if (selectionsData.value[id].type === 'bet_builder') {
      const isSelectionActive =
        Array.from(selectionsData.value[id].markets).filter(([, value]) => value.active).length > 1;

      if (!isSelectionActive) {
        selectionsData.value[id].odds = 0;
        return;
      }

      setBetslipLoading(true);

      const response = await getBetBuilderCalculations(
        selectionsData.value[id].eventId,
        store.getEventSelectionsForCalculation(selectionsData.value[id].eventId, id),
      );

      selectionsData.value[id] = {
        ...selectionsData.value[id],
        odds: (response?.odds ?? 1) * 10000,
      };

      setBetslipLoading(false);
    }
  }

  function validateBetslip() {
    sendBetslipWorkerMessage('validate_betslip', {
      winnings: calculationWinnings.value,
      payment: +stake.value,
      numberOfSelections: activeSelectionsCount.value,
      ticketType: selectedTicketType.value,
      systems: systems.value,
      selections: selectionsData.value,
    });
  }

  function updateBetCalculationData(data) {
    calculationWinnings.value = data.winnings.betslip.bets.reduce(
      (acc, n) => {
        acc.max += +n.pWinnings.max.total;
        acc.winningsBonus += n.pWinnings.max.bonuses.reduce((acc, n) => (acc += +n.amount), 0);
        acc.payinBonus += n.payin.bonuses.reduce((acc, n) => (acc += +n.amount), 0);

        if (acc.min > +n.pWinnings.min.total || !acc.min) acc.min = +n.pWinnings.min.total;
        return acc;
      },
      { max: 0, min: 0, winningsBonus: 0, payinBonus: 0 },
    );

    exchangedIdsCache.value = data.mappedIds;
    validateBetslip();
  }

  function updateStake(value) {
    alternativeStakeData.value.enabled = false;

    if (!value) emitter.emit('reset_predefined_stake_top_up');

    stake.value = value;
  }

  function clearSelectionsStake() {
    selectionsList.value?.forEach((id) => delete selectionsData.value[id].stake);
  }

  function updateSystem(data) {
    alternativeStakeData.value.enabled = false;

    systems.value[data.id] = {
      ...data,
    };

    requestBetCalculation();
    storeBetslipDataInLS();
  }

  function updateSystemStake(data) {
    alternativeStakeData.value.enabled = false;

    systems.value[data.id] = {
      ...data,
    };

    const totalStake = Object.values(systems.value).reduce(
      (acc, system) => acc + parseFloat(system.stake || 0),
      0,
    );

    updateStake(parseFloat(totalStake).toFixed(2));
  }

  function clearSystems() {
    systems.value = {};
  }

  function clearWinnings() {
    if (!calculationWinnings.value?.pWinnings) return;
    calculationWinnings.value.pWinnings.max.total = '0.00';
  }

  function checkForSystemCombinations() {
    if (!totalSystemCombinations.value && selectedTicketType.value === 'system') {
      store.addNotification({
        id: 'betslip_no_combinations',
        text: store.getTranslation('betslip_validation.bet_no_combinations'),
        type: 'warning',
        betslip: true,
      });
      return;
    }

    store.removeNotification('betslip_no_combinations');
  }

  function selectTicketType(value = 'combo') {
    alternativeStakeData.value.enabled = false;

    if (selectionsList.value.some((id) => selectionsData.value[id]?.type === 'bet_builder')) {
      store.addNotification({
        timeout: 2000,
        text: store.getTranslation('bet_builder_error_ticket_type'),
        type: 'error',
      });
      return;
    }

    selectedTicketType.value = value;

    clearSystems();
    calculationWinnings.value = null;

    if (value !== 'single') {
      const uniqSelectionsByEvent = {};
      const uniqEvents = {};

      for (const selectionId in selectionsData.value) {
        const selection = selectionsData.value[selectionId];

        if (!selection.betBuilderBets && !uniqEvents[selection.eventId]) {
          uniqSelectionsByEvent[selectionId] = selection;
          uniqEvents[selection.eventId] = 1;
        } else if (selection.betBuilderBets) {
          uniqSelectionsByEvent[selectionId] = selection;
        }
      }

      selectionsData.value = uniqSelectionsByEvent;
      selectionsList.value = Object.keys(uniqSelectionsByEvent);
    }

    checkForSystemCombinations();
    requestBetCalculation();
    storeBetslipDataInLS();
  }

  function shouldPreventCalculation() {
    if (selectedTicketType.value === 'system') {
      // No selected systems
      if (!availableSystems.value?.find((_, id) => systems.value[id]?.selected)) return;
    }

    return false;
  }

  function requestBetCalculation() {
    if (shouldPreventCalculation()) {
      validateBetslip();
      return;
    }

    sendCalculationWSMessage('calculation', {
      data: formatBerryBetPlacement({
        selections: selectionsData.value,
        stake: stake.value,
        cachedIds: exchangedIdsCache.value,
        selectedTicketType: selectedTicketType.value,
        systems: systems.value,
      }),
    });
  }

  const betslipSave = ref({});

  function clearBetslip() {
    betslipSave.value = {
      selectionsData: selectionsData.value,
      selectionsList: selectionsList.value,
      stake: stake.value,
      systems: systems.value,
      selectedTicketType: selectedTicketType.value,
    };

    const selectionsAsArr = Object.values(selectionsData.value).filter(
      (selection) => selection.type === 'bet_builder',
    );
    if (selectionsAsArr.length) {
      selectionsAsArr.forEach((selection) => {
        store.resetBetBuilderSelections(+selection.eventId);
      });
    }

    selectionsData.value = {};
    selectionsList.value = [];
    stake.value = store.punterPreferences?.defaultPayment ?? defaultStake.value;
    calculationWinnings.value = null;
    selectedTicketType.value = 'combo';
    betBuilderBetsBeingPlayed.value = false;
    clearSystems();
    clearWinnings();
  }

  function closeMobileBetslip() {
    router.push({
      path: store.route.path.replace('/v_betslip', store.isLive ? '' : '/'),
      query: store.route.query,
    });
  }

  function updatePreviousOddsRef(data = {}) {
    Object.keys(data).forEach((key) => {
      if (selectionsData.value[key]) {
        selectionsData.value[key] = {
          ...selectionsData.value[key],
          ...data[key],
        };
      }
    });
  }

  async function payin() {
    if (betProcessing.value) return;
    setBetProcessing(true);

    const bet = formatBetForBetslipPlacement({
      selectionsData: selectionsData.value,
      stake: stake.value,
      selectedTicketType: selectedTicketType.value,
      systems: systems.value,
    });

    if (isEmpty(bet)) {
      setBetProcessing(false);
      return;
    }

    if (alternativeStakeData.value?.enabled) {
      bet.bets.forEach((bet) => {
        bet.relation = {
          betId: alternativeStakeData.value.betId,
          type: 'ALT-STAKE',
        };
      });

      try {
        await acceptAlternativeStakeBet({
          bet,
          ...alternativeStakeData.value,
        });
      } catch (e) {
        setBetProcessing(false);
      }

      return;
    }

    try {
      pendingPlacedBets.value[bet.reqUuid] = true;
      await placeBet(bet);

      let counter = 0;
      const interval = setInterval(async () => {
        counter += 1;

        if (counter > 5 || !betProcessing.value || !pendingPlacedBets.value[bet.reqUuid]) {
          clearInterval(interval);
          return;
        }

        try {
          const response = await checkBetslip(bet.reqUuid);

          const { bets } = response.data.betslip;
          if (bets.length && bets.every((bet) => bet.phase !== 'PREPARED')) {
            setBetProcessing(false);
          }

          const acceptedBets = [];
          const rejectedBets = [];
          let acceptedBetsCount = 0;
          let rejectedBetsCount = 0;
          bets.forEach((bet) => {
            if (bet.phase === 'PLACED' && bet.resolutionStatuses?.[0]?.betState === 'OPEN') {
              acceptedBetsCount += 1;
              acceptedBets.push(bet);
            }

            if (bet.phase === 'REJECTED') {
              rejectedBetsCount += 1;
              rejectedBets.push(bet);
            }
          });

          if (acceptedBetsCount)
            handleBetslipAfterPlacedBet({
              acceptedBets,
              rejectedBetsCount,
            });

          if (!rejectedBetsCount) return;

          if (rejectedBetsCount === 1) {
            const rejectedReasons = rejectedBets[0].reasons?.map(({ reason }) => reason);
            displayBetRejectedReasons(rejectedReasons);
            return;
          }

          displayMultipleRejectedBetsMessage({
            showRejectedBetsCount: !!acceptedBetsCount,
            rejectedBetsCount,
          });
        } catch (error) {
          console.error(error);
        }
      }, 5000);
    } catch (e) {
      pendingPlacedBets.value[bet.reqUuid] = false;
      setBetProcessing(false);
      store.addNotification({
        timeout: 5000,
        text: 'Ticket has been rejected',
        type: 'error',
      });

      console.log('Bet could not be placed', e);
    }
  }

  function sendBetslipWorkerMessage(message, data) {
    betslipWorker.postMessage(JSON.stringify({ message, data }));
  }

  betslipWorker.onmessage = ({ data: message }) => {
    const { event, data } = message;

    if (event === 'pascal_triangle') pascalTriagle.value = data;
    if (event === 'betslip_validation') {
      breachedLimits.value = data.breachedLimits;
      limits.value = data.limits;
    }
  };

  const betslipLoading = ref(false);

  function setBetslipLoading(val) {
    betslipLoading.value = val;
  }

  function removeBetBuilderBet(selectionId, outcomeId, marketId) {
    const isDeleted = selectionsData.value[selectionId]?.markets.delete(`${marketId}/${outcomeId}`);

    if (!selectionsData.value[selectionId]?.markets?.size) {
      removeSelection(selectionId);
    }

    return isDeleted;
  }

  function handleBetslipAfterPlacedBet({ acceptedBets, rejectedBetsCount }) {
    const resetBetslip =
      !rejectedBetsCount &&
      (store.punterPreferences?.clearBetslipAfterPayment ??
        store.config?.betslip?.clearBetslipAfterPayment);

    store.addPlacedBetsToBetList(acceptedBets);

    displayAcceptedBetsMessage({
      acceptedBetsCount: acceptedBets.length,
      showAcceptedBetsCount: !!rejectedBetsCount,
      showInBetslip: !resetBetslip,
    });

    if (resetBetslip) {
      clearBetslip();
      closeMobileBetslip();
    }
  }

  function displayAcceptedBetsMessage({ acceptedBetsCount, showAcceptedBetsCount, showInBetslip }) {
    const message = showAcceptedBetsCount
      ? store.getTranslation('accepted_bets_count', { value: acceptedBetsCount })
      : store.getTranslation('ticket_accepted');

    store.addNotification({
      timeout: 6000,
      text: message,
      type: 'success',
      betslip: showInBetslip,
    });
  }

  function displayMultipleRejectedBetsMessage({ showRejectedBetsCount, rejectedBetsCount }) {
    const rejectedMessage = showRejectedBetsCount
      ? store.getTranslation('rejected_bets_count', { value: rejectedBetsCount })
      : store.getTranslation('ticket_rejected');

    store.addNotification({
      timeout: 6000,
      text: rejectedMessage,
      type: 'error',
      betslip: true,
    });
  }

  function displayBetRejectedReasons(reasons) {
    const messages = reasons?.length ? reasons : [store.getTranslation('ticket_rejected')];

    messages.forEach((messaage) => {
      store.addNotification({
        timeout: 6000,
        text: messaage,
        type: 'error',
        betslip: true,
      });
    });
  }

  const showOdds = computed(() => {
    return selectionsList.value.some((selection) => selection.includes('bet_builder'))
      ? selectionsData.value[selectionsList.value[0]].markets.size > 1 &&
          totalSelectionsOdds.value > 0
      : true;
  });

  /**
   * Stores the betslip data in local storage.
   *
   * This function saves the user's betslip data into the local storage. It stores bet information
   * such as selected ticket type, systems (if applicable), selections, and stake.
   */
  function storeBetslipDataInLS() {
    const selectionsDataValues = Object.values(selectionsData.value ?? {});
    const selectedBetType = selectedTicketType.value;

    setItemToStorage('user-bet', {
      selectedTicketType: selectedBetType,
      ...(selectedBetType === 'system' && { systems: systems.value }),
      selections: selectionsDataValues.map((selection) => ({
        ...selection,
        markets: [...selection.markets],
      })),
      stake: stake.value,
    });
  }

  function handleAlternativeStakes(bets, betslipId) {
    const currency = window.currency?.toUpperCase();

    bets.forEach((bet) => {
      if (bet.suggestion?.type !== 'ALT-STAKE') return;

      const amount = bet.suggestion?.stake?.amount;
      const message = store.getTranslation('betslip_alt_stake_warning', { amount, currency });

      store.addNotification({
        timeout: 6000,
        text: message,
        type: 'warning',
        betslip: true,
      });

      switch (selectedTicketType.value) {
        case 'combo':
          updateStake(amount);
          break;
        case 'single':
          break;
        case 'system':
          break;
      }

      alternativeStakeData.value = {
        enabled: true,
        amount,
        betslipId,
        betId: bet.betId,
      };
    });
  }

  function updatePendingPlacedBets({ id, value }) {
    pendingPlacedBets.value[id] = value;
  }

  return {
    // Actions
    addSelection,
    removeSelection,
    toggleSelection,
    updateBetCalculationData,
    updateStake,
    clearBetslip,
    closeMobileBetslip,
    requestBetCalculation,
    selectTicketType,
    updateSystem,
    updateSystemStake,
    clearSystems,
    clearSelectionsStake,
    updatePreviousOddsRef,
    setBetProcessing,
    payin,
    sendBetslipWorkerMessage,
    updatePendingPlacedBets,

    // State
    calculationWinnings,
    selectedTicketType,
    selectedBetType,
    selectionsData,
    selectionsList,
    stake,
    systems,
    betProcessing,
    pendingPlacedBets,

    // Computed
    activeSelectionsCount,
    availableSystems,
    splitStakePerSelection,
    totalSelectionsOdds,
    totalSelectionsStake,
    selectionHasSingleStakeSet,
    selectionsByEvent,
    totalSystemCombinations,
    defaultStake,

    pascalTriagle,
    limits,
    breachedLimits,
    checkForSystemCombinations,

    betslipSave,
    betslipLoading,
    setBetslipLoading,
    removeBetBuilderBet,

    betBuilderBetsBeingPlayed,

    handleBetslipAfterPlacedBet,
    displayMultipleRejectedBetsMessage,
    displayBetRejectedReasons,
    showOdds,
    exchangedIdsCache,

    storeBetslipDataInLS,

    handleAlternativeStakes,
  };
});
