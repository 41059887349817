import { uniq, has } from 'lodash-es';

import { useStore } from '@/stores/store';
import { useBetslipStore } from '@/stores/BetslipStore';
import { formatLiveMetadata } from '@/services/formatters/metadata';
import { checkEventFitmentInOffer, mapEvent } from '@/services/offer-adapter';
import { subscribeEventsOnMetadataChanges } from '@/services/sockets/offer-distribution/index.js';

const collections = ['events', 'promotedEvents'];

let eventsAvailableQueue = [];
let eventsAvailableQueueTimer = null;

export function handleWSMessage(data) {
  const store = useStore();
  const betslipStore = useBetslipStore();

  switch (data.type) {
    case 'sports_update':
      store.setFilterData({
        sports: data.sports,
      });

      for (const sport of data.sports) {
        const inStateSport = store.sports.get(sport.id) ?? {};
        store.sports.set(sport.id, {
          ...inStateSport,
          ...sport,
        });

        for (const selectionId of betslipStore.selectionsList) {
          const selection = betslipStore.selectionsData[selectionId];
          if (
            selection.sportId === sport.id &&
            betslipStore.selectionsData[selection.id].limits.sport
          )
            delete betslipStore.selectionsData[selection.id].limits.sport;
        }
      }

      break;
    case 'sports_remove':
      for (const id of data.ids) {
        store.sports.delete(id);
        delete store.filterSports[id];

        for (const selectionId of betslipStore.selectionsList) {
          const selection = betslipStore.selectionsData[selectionId];
          if (selection.sportId === id)
            betslipStore.selectionsData[selection.id].limits.sport = true;
        }
      }
      break;

    case 'categories_update':
      store.setFilterData({
        categories: data.categories,
      });

      for (const category of data.categories) {
        const inStateCategory = store.categories.get(category.id) ?? {};
        store.categories.set(category.id, {
          ...inStateCategory,
          ...category,
        });

        for (const selectionId of betslipStore.selectionsList) {
          const selection = betslipStore.selectionsData[selectionId];
          if (
            selection.categoryId === category.id &&
            betslipStore.selectionsData[selection.id].limits.category
          )
            delete betslipStore.selectionsData[selection.id].limits.category;
        }
      }

      break;
    case 'categories_remove':
      for (const id of data.ids) {
        store.categories.delete(id);

        delete store.filterCategories[id];

        for (const selectionId of betslipStore.selectionsList) {
          const selection = betslipStore.selectionsData[selectionId];
          if (selection.categoryId === id)
            betslipStore.selectionsData[selection.id].limits.category = true;
        }
      }

      break;

    case 'tournaments_update':
      store.setFilterData({
        tournaments: data.tournaments,
      });

      for (const tournament of data.tournaments) {
        const inStateTournament = store.tournaments.get(tournament.id);
        store.tournaments.set(tournament.id, { ...inStateTournament, ...tournament });

        for (const selectionId of betslipStore.selectionsList) {
          const selection = betslipStore.selectionsData[selectionId];
          if (
            selection.tournamentId === tournament.id &&
            betslipStore.selectionsData[selection.id].limits.tournament
          )
            delete betslipStore.selectionsData[selection.id].limits.tournament;
        }
      }

      break;
    case 'tournaments_remove':
      for (const id of data.ids) {
        store.tournaments.delete(id);
        delete store.filterTournaments[id];

        for (const selectionId of betslipStore.selectionsList) {
          const selection = betslipStore.selectionsData[selectionId];
          if (selection.tournamentId === id)
            betslipStore.selectionsData[selection.id].limits.tournament = true;
        }
      }

      break;

    case 'markets_update':
      store.useOfferAdapter('MARKETS_UPDATE', data.markets);

      for (const market of data.markets) {
        for (const selectionId of betslipStore.selectionsList) {
          const selection = betslipStore.selectionsData[selectionId];
          if (
            selection.metaMarketId === market.id &&
            betslipStore.selectionsData[selection.id].limits.metaMarket
          )
            delete betslipStore.selectionsData[selection.id].limits.metaMarket;
        }
      }

      break;

    case 'markets_remove':
      for (const id of data.ids) {
        for (const collection of ['markets', 'eventviewMarkets']) {
          if (store[collection]?.has(id)) store[collection].delete(id);
        }

        for (const selectionId of betslipStore.selectionsList) {
          const selection = betslipStore.selectionsData[selectionId];
          if (selection.metaMarketId === id)
            betslipStore.selectionsData[selection.id].limits.metaMarket = true;
        }
      }
      break;

    case 'outcomes_update':
      {
        for (const outcome of data.outcomes) {
          const sportId = (
            store?.markets?.get(outcome.marketId) ?? store?.markets?.get(outcome.marketId)
          )?.sportId;

          if (store.eventviewId && store.eventviewEvent?.sportId === sportId)
            store.eventviewOutcomes.set(outcome.id, outcome);

          if (store.eventviewMetadataRef?.has(sportId))
            store.eventviewMetadataRef.get(sportId)?.outcomes.set(outcome.id, outcome);

          store.outcomes.set(outcome.id, outcome);

          for (const selectionId of betslipStore.selectionsList) {
            const selection = betslipStore.selectionsData[selectionId];
            if (selection.metaOutcomeId === outcome.id && selection.limits.metaOutcome)
              delete betslipStore.selectionsData[selection.id].limits.metaOutcome;
          }
        }
      }

      break;

    case 'outcomes_remove':
      {
        for (const id of data.ids) {
          if (store.outcomes.has(id)) store.outcomes.delete(id);
          if (store.eventviewOutcomes.has(id)) store.eventviewOutcomes.delete(id);

          const inStateOutcome = store.outcomes.get(id) ?? store.eventviewOutcomes.get(id);
          const sportId = (
            store.markets?.get(inStateOutcome?.marketId) ?? store.eventviewOutcomes.get(id)
          )?.sportId;

          if (store.eventviewMetadataRef.get(sportId)?.outcomes?.has(id))
            store.eventviewMetadataRef.get(sportId)?.outcomes?.delete(id);

          for (const selectionId of betslipStore.selectionsList) {
            const selection = betslipStore.selectionsData[selectionId];
            if (selection.metaOutcomeId === id)
              betslipStore.selectionsData[selection.id].limits.metaOutcome = true;
          }
        }
      }

      break;

    case 'events_remove':
    case 'promoted_offer_catalog_events_remove':
      {
        if (data.promotedOfferId && !store.promoOffer.get(data.promotedOfferId)) {
          return;
        }

        if (data.promotedOfferCatalogId && !store.catalogs.get(data.promotedOfferCatalogId)) {
          return;
        }

        const favorites = store.punterPreferences?.['favouriteEvents'] ?? {};

        if (store.isLive && data.game === 1) return;

        for (const id of data.ids) {
          if (favorites[id]) delete favorites[id];
          store.updatePunterPreferences({ key: 'favouriteEvents', value: favorites });
          if (store.eventviewId === id) {
            store.eventviewId = null;
            store.eventviewEvent = null;
          }
          if (store.events?.has(id)) {
            store.events.delete(id);
            store.eventsList.splice(
              store.eventsList.findIndex((i) => i === id),
              1,
            );
          }

          if (store.promotedEvents?.has(id)) {
            store.promotedEvents.delete(id);
            if (store.promotedEventsList.length)
              store.promotedEventsList.splice(
                store.promotedEventsList.findIndex((i) => i === id),
                1,
              );
          }

          for (const selectionId of betslipStore.selectionsList) {
            const [eventId] = selectionId.split('/');
            if (id === +eventId) betslipStore.selectionsData[selectionId].limits.event = true;
          }
        }
      }

      break;

    case 'events_available':
    case 'promoted_offer_catalog_events_available':
      if (data.promotedOfferId) {
        const promoFilter = store.promoFilterOffers[data.promotedOfferId];
        store.promoFilterOffers[promoFilter?.id ?? data.promotedOfferId] = {
          ...promoFilter,
          numberOfEvents: (promoFilter?.numberOfEvents ?? 0) + data.events.length,
        };
      }

      if (data.promotedOfferCatalogId) {
        const promoFilter = store.promoFilterCatalogs[data.promotedOfferCatalogId];
        store.promoFilterCatalogs[promoFilter?.id ?? data.promotedOfferCatalogId] = {
          ...promoFilter,
          numberOfEvents: (promoFilter?.numberOfEvents ?? 0) + data.events.length,
        };
      }

      for (const item of data.events) {
        const { event, category } = item;

        if (
          !checkEventFitmentInOffer({
            eventsList: store.eventsList,
            isLive: store.isLive,
            timeFilters: store.timeFilters,
            selectedFilters: store.selectedFiltersExternal,
            event: {
              ...event,
              sportId: category.sportId,
              categoryId: category.id,
            },
          })
        )
          continue;

        eventsAvailableQueue.push(event.id);

        const activeGame = store.isLive;
        if (!eventsAvailableQueueTimer) {
          eventsAvailableQueueTimer = setTimeout(() => {
            if (activeGame !== store.isLive) return;

            clearTimeout(eventsAvailableQueueTimer);

            store.sendOfferWorkerMessage('load-and-format-events', {
              newEvents: eventsAvailableQueue,
              offerEventsList: store.eventsList,
              offerEventsData: [...(store?.events ?? [])],
              tournaments: [...(store?.tournaments ?? [])],
              categories: [...(store?.categories ?? [])],
              sports: [...(store?.sports ?? [])],
              tournamentRounds: [...(store?.tournamentRounds ?? [])],
              tenantUuid: window.tenantUuid,
              languageCode: window.languageCode,
              playerUuid: window.punter?.id,
              isLive: store.isLive,
              ...(store.config?.supplemental?.list
                ? { supplementalNameTypeIds: store.config.supplemental.list }
                : {}),
            });

            eventsAvailableQueueTimer = null;
            eventsAvailableQueue = [];
          }, 10000);
        }

        for (const selectionId of betslipStore.selectionsList) {
          const [eventId] = selectionId.split('/');
          if (event.id === +eventId) delete betslipStore.selectionsData[selectionId].limits.event;
        }
      }
      break;

    case 'events_odds_full_update':
      store.useOfferAdapter('FULL_ODDS_UPDATE', data.events);
      break;

    case 'events_odds_delta_update':
      {
        const timeoutReferences = new Map();

        for (const { id: eventId, totalMarketCount, markets } of data.events) {
          for (const collection of collections) {
            if (store[collection]?.has(eventId))
              store[collection].get(eventId).totalMarketCount = totalMarketCount;
          }

          for (const market of markets) {
            for (const collection of collections) {
              const inStateEventMarket = store[collection]?.get(eventId)?.markets?.get(market.id);
              if (!inStateEventMarket) {
                store[collection]
                  ?.get(eventId)
                  ?.markets?.set(market.id, { ...market, outcomes: new Map() });
              } else {
                if (has(market, 'status')) inStateEventMarket.status = market.status;
                if (has(market, 'mostBalanced'))
                  inStateEventMarket.mostBalanced = market.mostBalanced;
              }
            }

            if (store.eventviewId === eventId) {
              const inStateEventMarket = store.eventviewEvent?.markets?.get(market.id);
              if (!inStateEventMarket)
                store.eventviewEvent?.markets?.set(market.id, { ...market, outcomes: new Map() });
              else {
                if (has(market, 'status')) inStateEventMarket.status = market.status ?? 0;
                if (has(market, 'mostBalanced'))
                  inStateEventMarket.mostBalanced = market.mostBalanced;
              }
            }

            if (has(market, 'status')) {
              const betslipSelections = betslipStore.selectionsList.filter((id) =>
                id.includes(`${eventId}/${market.id}`),
              );

              for (const selectionId of betslipSelections) {
                if (!market.status) betslipStore.selectionsData[selectionId].limits.market = true;
                else delete betslipStore.selectionsData[selectionId].limits.market;
              }
            }

            for (const outcome of market?.outcomes ?? []) {
              const timeRefIdx = `${eventId}/${market.id}/${outcome.id}`;
              timeoutReferences.set(timeRefIdx, 1);

              for (const collection of collections) {
                const inStateEventMarketOutcome =
                  (store[collection]?.get(eventId)?.markets?.get(market.id)?.outcomes.size &&
                    store[collection]
                      ?.get(eventId)
                      ?.markets?.get(market.id)
                      ?.outcomes?.get(outcome.id)) ||
                  null;

                if (!inStateEventMarketOutcome) {
                  store[collection]
                    ?.get(eventId)
                    ?.markets?.get(market.id)
                    ?.outcomes?.set(outcome.id, outcome);
                } else {
                  inStateEventMarketOutcome.previousOdds = inStateEventMarketOutcome.odds;
                  inStateEventMarketOutcome.odds = outcome.odds;

                  if (has(outcome, 'status')) inStateEventMarketOutcome.status = outcome.status;
                  if (has(market, 'mostBalanced'))
                    inStateEventMarketOutcome.mostBalanced = outcome.mostBalanced;
                }
              }

              if (store.eventviewId === eventId) {
                const inStateEventMarketOutcome =
                  (store.eventviewEvent?.markets?.get(market.id)?.outcomes?.size &&
                    store.eventviewEvent?.markets?.get(market.id)?.outcomes?.get(outcome.id)) ||
                  null;

                if (!inStateEventMarketOutcome) {
                  store.eventviewEvent?.markets?.get(market.id)?.outcomes?.set(outcome.id, outcome);
                } else {
                  inStateEventMarketOutcome.previousOdds = inStateEventMarketOutcome.odds;
                  inStateEventMarketOutcome.odds = outcome.odds;
                  if (has(outcome, 'status')) inStateEventMarketOutcome.status = outcome.status;
                  if (has(market, 'mostBalanced'))
                    inStateEventMarketOutcome.mostBalanced = outcome.mostBalanced;
                }
              }

              const betslipSelection = betslipStore.selectionsData?.[timeRefIdx];
              if (betslipSelection) {
                betslipStore.selectionsData[timeRefIdx].previousOdds =
                  betslipStore.selectionsData[timeRefIdx].odds;
                betslipStore.selectionsData[timeRefIdx].odds = outcome.odds;

                if (has(outcome, 'status')) {
                  if (outcome.status === 1)
                    delete betslipStore.selectionsData[timeRefIdx].limits.outcome;
                  else betslipStore.selectionsData[timeRefIdx].limits.outcome = true;
                }
              }
            }
          }
        }

        const timeout = setTimeout(() => {
          clearTimeout(timeout);

          for (const [ref] of timeoutReferences) {
            const [e, em, emo] = ref.split('/');
            const eventId = +e;
            const eventMarketId = +em;
            const eventMarketOutcomeId = +emo;

            for (const collection of collections) {
              if (
                store[collection]
                  ?.get(eventId)
                  ?.markets?.get(eventMarketId)
                  ?.outcomes?.has(eventMarketOutcomeId)
              )
                store[collection]
                  .get(eventId)
                  .markets.get(eventMarketId)
                  .outcomes.get(eventMarketOutcomeId).previousOdds = null;
            }

            if (
              store.eventviewEvent?.markets?.get(eventMarketId)?.outcomes?.get(eventMarketOutcomeId)
            )
              store.eventviewEvent.markets
                .get(eventMarketId)
                .outcomes.get(eventMarketOutcomeId).previousOdds = 0;

            if (betslipStore.selectionsData[ref]) betslipStore.selectionsData[ref].previousOdds = 0;
          }
        }, 5000);
      }
      break;
    case 'events_markets_metadata_update':
      /**
       * Property updateType defines does message contains full (1) or partial (2) update.
          - In case of full update (1) - Apply update on received event market/outcomes
              and remove all event markets and outcomes which are not received on this message.
          - In case of partial update (2) - Apply update on received event market/outcomes.
       */
      store.updateEventMetadata(data.events, data.updateType);
      break;
    case 'events_full_metadata_update':
      {
        const eventsToSubscribeOnMetadata = new Set();

        for (const { category, tournament, event: rawEvent } of data.events) {
          store.categories.set(category.id, { ...store.categories.get(category.id), ...category });
          store.tournaments.set(tournament.id, {
            ...store.tournaments.get(tournament.id),
            ...tournament,
          });

          let event = null;

          for (const collection of ['events', 'promotedEvents']) {
            if (store[collection]?.has(rawEvent.id)) {
              if (!event) event = mapEvent(rawEvent, store[collection].get(rawEvent.id)?.markets);
              store[collection].set(event.id, { ...store[collection].get(event.id), ...event });

              if (event.playStatus === 2) eventsToSubscribeOnMetadata.add(event.id);
            }
          }

          if (store.eventviewId === rawEvent.id) {
            event = mapEvent(rawEvent, store.eventviewEvent.markets);
            store.eventviewEvent = { ...store.eventviewEvent, ...event };

            if (event.playStatus === 2) eventsToSubscribeOnMetadata.add(event.id);
          }

          for (const selectionId of betslipStore.selectionsList) {
            const [eventId] = selectionId.split('/');
            if (rawEvent.id !== +eventId) return;

            const selection = betslipStore.selectionsData[selectionId];
            const eventGame = rawEvent.playStatus === 2 ? 'LIVE' : 'PREMATCH';

            if (selection.game !== eventGame) selection.game = eventGame;
            if (rawEvent?.playStatus === 2) eventsToSubscribeOnMetadata.add(event.id);
          }
        }

        subscribeEventsOnMetadataChanges([...eventsToSubscribeOnMetadata]);
      }
      break;

    case 'events_live_metadata_update':
      formatLiveMetadata(data.events);
      break;

    case 'events_live_metadata_remove':
      for (const id of data.ids) {
        if (store.eventviewId === id) store.eventviewEvent.metadata = null;
        if (store.events.get(id)) {
          store.events.get(id).metadata = null;
        }
      }
      break;

    case 'teams_update':
    case 'players_update':
      {
        const mapEventCompetitors = (competitors, team) =>
          competitors?.map((competitor) => ({
            ...competitor,
            ...(team.id === competitor.typeId ? team : {}),
          }));

        for (const item of data?.teams ?? data?.players ?? []) {
          const eventIds = uniq([
            ...store.eventsList,
            ...(store.promotedEventsList ?? []),
            ...[store.eventviewId],
          ]);

          for (const id of eventIds) {
            for (const collection of ['events', 'promotedEvents']) {
              if (store[collection]?.has(id) && store[collection].get(id)?.competitors?.length)
                store[collection].get(id).competitors = mapEventCompetitors(
                  store[collection].get(id).competitors,
                  item,
                );
            }

            if (store.eventviewId === id && store.eventviewEvent?.competitors?.length)
              store.eventviewEvent.competitors = mapEventCompetitors(
                store.eventviewEvent.competitors,
                item,
              );
          }
        }
      }

      break;

    case 'teams_remove':
    case 'players_remove':
      {
        const filterOutCompetitors = (id, currentCompetitors) =>
          currentCompetitors?.filter((competitor) => competitor.typeId !== id) ?? [];

        for (const id of data.ids) {
          const eventIds = uniq([...store.eventsList, ...(store.promotedEventsList ?? [])]);
          for (const eventId of eventIds)
            if (store.events.get(eventId)) {
              store.events.get(eventId).competitors = filterOutCompetitors(
                id,
                store.events.get(eventId).competitors,
              );
            }

          if (store.eventviewEvent) {
            store.eventviewEvent.competitors = filterOutCompetitors(
              id,
              store.eventviewEvent.competitors ?? [],
            );
          }
        }
      }
      break;

    case 'market_groups_update':
      store.useOfferAdapter('MARKET_GROUPS_UPDATE', data.marketGroups);
      break;

    case 'market_groups_remove':
      store.useOfferAdapter('MARKET_GROUPS_REMOVE', data.ids);
      break;

    case 'promoted_offers_update':
      store.setPromoOfferFilterData({
        promotedOffers: data.promotedOffers,
      });

      for (const offer of data.promotedOffers) {
        const inStateOffer = store.promoOffer.get(offer.id) ?? {};
        store.promoOffer.set(offer.id, {
          ...inStateOffer,
          ...offer,
        });
      }
      break;

    case 'promoted_offers_remove':
      for (const id of data.ids) {
        store.promoOffer.delete(id);

        delete store.promoFilterOffers[id];
      }
      break;

    case 'promoted_offer_catalogs_update':
      store.setPromoOfferFilterData({
        promotedOfferCatalogs: data.promotedOfferCatalogs,
      });

      for (const catalog of data.promotedOfferCatalogs) {
        const inStateCatalog = store.catalogs.get(catalog.id) ?? {};
        store.catalogs.set(catalog.id, {
          ...inStateCatalog,
          ...catalog,
        });
      }
      break;

    case 'promoted_offer_catalogs_remove':
      for (const id of data.ids) {
        store.catalogs.delete(id);

        delete store.promoFilterCatalogs[id];
      }
      break;

    // @ToDo: Implement missing messages
    // case 'TODO_tournament_rounds_update':
    // case 'TODO_tournament_rounds_remove':
  }
}
