import axios from 'axios';

const { VITE_CASHOUT_API } = import.meta.env;

const http = axios.create({
  baseURL: VITE_CASHOUT_API,
});

export async function getCashoutBetList(data = {}) {
  try {
    const response = await http({
      method: 'GET',
      url: `/tenants/${window.tenantUuid}/players/${window.punter?.id}/bet-profiles`,
      headers: {
        Authorization: `Bearer ${punterToken || ''}`,
      },
      params: data,
    });

    return response.data;
  } catch (e) {
    console.log(e);
  }
}

export async function calculateCashout(data) {
  try {
    const response = await http({
      method: 'POST',
      url: `/tenants/${window.tenantUuid}/players/${window.punter?.id}/bet-profiles/status`,
      headers: {
        Authorization: `Bearer ${punterToken || ''}`,
      },
      data,
    });

    return response.data;
  } catch (e) {
    console.log(e);
  }
}

export async function executeCashout(id, data) {
  try {
    const response = await http({
      method: 'POST',
      url: `/tenants/${window.tenantUuid}/players/${window.punter?.id}/bet-profiles/${id}`,
      headers: {
        Authorization: `Bearer ${punterToken || ''}`,
      },
      data,
    });

    return response.data;
  } catch (e) {
    console.log(e);
  }
}
