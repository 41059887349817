import axios from 'axios';

const { VITE_PUNTER_PREFERENCES } = import.meta.env;

const http = axios.create({
  baseURL: `${VITE_PUNTER_PREFERENCES}/status`,
});

export async function checkOnline() {
  try {
    const response = await http({ method: 'GET' });
    return response?.status === 200;
  } catch (error) {
    return false;
  }
}
