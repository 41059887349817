const statusMap = {
  1: 'NS',
  2: '1S',
  3: '1B',
  4: '2S',
  5: '2B',
  6: '3S',
  7: '3B',
  8: '4S',
  9: '4B',
  10: '5S',
  11: '5B',
  12: '6S',
  13: '6B',
  14: '7S',
  15: '7B',
  16: '8S',
  17: '8B',
  18: '9S',
  19: '9B',
  20: '10S',
  21: '10B',
  22: '11S',
  23: '11B',
  24: '12S',
  25: '12B',
  26: '13S',
  27: 'IP',
  28: 'END',
  29: 'BREAK',
};

const scorePeriodsMap = {
  1: '1S',
  2: '2S',
  3: '3S',
  4: '4S',
  5: '5S',
  6: '6S',
  7: '7S',
  8: '8S',
  9: '9S',
  10: '10S',
  11: '11S',
  12: '12S',
  13: '13S',
};

function mapPeriods(periods = []) {
  if (!periods?.length) return [];

  return periods.reduce((score, { id, value: n }) => {
    score.push({ label: scorePeriodsMap[id], scores: [n.home, n.away] });
    return score;
  }, []);
}

export function mapDartsEvent(event) {
  return {
    displayEventStatus: true,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
    periods: mapPeriods(event.events?.find((event) => event.id === 4)?.periods),
    currentScoreInCurrentSet: event.events?.find((event) => event.id === 2)?.value ?? {},
    currentGameScore: event.events.find((event) => event.id === 3)?.value ?? null,
    server: event.events?.find((event) => event.id === 5)?.value ?? {},
  };
}
