const statusMap = {
  1: 'NS',
  2: '1R',
  3: '1B',
  4: '2R',
  5: '2B',
  6: '3R',
  7: '3B',
  8: '4R',
  9: '4B',
  10: '5R',
  11: '5B',
  12: 'END',
};

export function mapMMAEvent(event) {
  return {
    displayEventStatus: true,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
  };
}
