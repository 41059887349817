import { createRouter, createWebHistory } from 'vue-router';

import BettingArea from '@/views/BettingArea.vue';
import BetDetails from '@/views/BetDetails.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:filters*',
      name: 'prematch',
      component: BettingArea,
    },
    {
      path: '/live/:filters*',
      name: 'live',
      component: BettingArea,
    },
    {
      path: '/bet-details',
      name: 'bet-details',
      component: BetDetails,
    },
  ],
});

export default router;
