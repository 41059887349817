/**
 * @description function to check does session or local storage exists
 * @param {string} type - supported values 'sessionStorage' & 'localStorage'
 * @returns {{}} sessionStorage or localStorage if exists
 */
const getStorageType = (type) => {
  let storageType;

  if (type === 'sessionStorage') {
    try {
      storageType = window.sessionStorage;
    } catch (error) {
      console.log('session not supported');
    }
  } else {
    try {
      storageType = localStorage;
    } catch (error) {
      console.log('localstorage not supported');
    }
  }

  return storageType || {};
};

/**
 * @description sets data in wanted storage under wanted key
 * @param {string} key
 * @param {{}} data
 * @param {string} storageType - Default value 'localStorage'
 * @returns data from storage or null if it doesn't exist
 */
export const setItemToStorage = (key, data, storageType = 'localStorage') => {
  try {
    return getStorageType(storageType)?.setItem(key, JSON.stringify(data));
  } catch (error) {
    return null;
  }
};

/**
 * @description removes data in wanted storage under wanted key
 * @param {string} key
 * @param {string} storageType - Default value 'localStorage'
 * @returns null
 */
export const removeItemFromStorage = (key, storageType = 'localStorage') => {
  try {
    return getStorageType(storageType)?.removeItem(key);
  } catch (error) {
    return null;
  }
};

/**
 * @description gets data from wanted storage under wanted key
 * @param {string} key
 * @param {string} storageType - Default value 'localStorage'
 * @returns data from storage or null if it doesn't exist
 */
export const getItemFromStorage = (key, storageType = 'localStorage') => {
  try {
    return JSON.parse(getStorageType(storageType)?.getItem(key));
  } catch (error) {
    return null;
  }
};
