const statusMap = {
  1: 'NS',
  2: '1IT',
  3: '1IB',
  4: '2IT',
  5: '2IB',
  6: '3IT',
  7: '3IB',
  8: '4IT',
  9: '4IB',
  10: '5IT',
  11: '5IB',
  12: '6IT',
  13: '6IB',
  14: '7IT',
  15: '7IB',
  16: '8IT',
  17: '8IB',
  18: '9IT',
  19: '9IB',
  20: 'EIT',
  21: 'EIB',
  22: 'BT1 B1',
  23: 'BT2 B1',
  24: 'BT2 B2',
  25: 'BT3 B2',
  26: 'BT3 B3',
  27: 'BT4 B3',
  28: 'BT4 B4',
  29: 'BT5 B4',
  30: 'BT5 B5',
  31: 'BT6 B5',
  32: 'BT6 B6',
  33: 'BT7 B6',
  34: 'BT7 B7',
  35: 'BT8 B7',
  36: 'BT8 B8',
  37: 'BT9 B8',
  38: 'BT9 B9',
  39: 'BTEI B9',
  40: 'BTEI BEI',
  41: 'END',
  42: 'END after EI',
};

const scorePeriodsMap = {
  1: '1I',
  2: '1I',
  3: '2I',
  4: '2I',
  5: '3I',
  6: '3I',
  7: '4I',
  8: '4I',
  9: '5I',
  10: '5I',
  11: '6I',
  12: '6I',
  13: '7I',
  14: '7I',
  15: '8I',
  16: '8I',
  17: '9I',
  18: '9I',
  19: 'EI',
  20: 'EI',
};

function mapBaseballPeriods(periods) {
  return periods.reduce((score, { id, value: n }) => {
    score.push({ label: scorePeriodsMap[id], scores: [n.home, n.away] });
    return score;
  }, []);
}

export function mapBaseballEvent(event) {
  return {
    displayEventStatus: true,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
    periods: mapBaseballPeriods(event.events.find((event) => event.id === 3)?.periods ?? []),
    currentGameScore: event.events.find((event) => event.id === 1)?.value ?? null,
    eventTime: null,
    hidePeriods: true,
  };
}
