import { io } from 'socket.io-client';
import { useStore } from '@/stores/store';

const { VITE_CASHOUT_SOCKET } = import.meta.env;

let socketConnection;

export function establishCashoutSocketConnection(punter) {
  const store = useStore();

  socketConnection = io(VITE_CASHOUT_SOCKET, {
    path: '/client/distribution/sio',
    query: {
      playerId: punter.id,
    },
    transports: ['websocket'],
    auth: {
      token: punterToken,
    },
  });

  socketConnection.on('connect', () => {
    console.log('cashout connection established');
    store.loadPunterBets();
  });

  socketConnection.on('connect_error', (e) => {
    console.log('connect_error', e);
  });

  socketConnection.on('error', (error) => {
    console.log('error occurred', error);
  });

  socketConnection.on('calculationResult', (data) => {
    store.updateCashoutBets(JSON.parse(data));
  });
}
