<script setup>
import { toRef } from 'vue';

import Icon from '@/components/common/Icon.vue';

const emit = defineEmits(['copy']);

const props = defineProps({
  source: {
    type: String,
    required: true,
    default: '',
  },
});

const source = toRef(props, 'source');

const copyToClipboard = (textToCopy) => {
  const input = document.createElement('input');
  input.value = textToCopy;

  document.body.appendChild(input);

  input.select();

  document.execCommand('copy');

  document.body.removeChild(input);

  emit('copy');
};
</script>

<template>
  <div>
    <Icon
      icon="n-i-copy"
      @click="copyToClipboard(source)" />
  </div>
</template>
