import axios from 'axios';

import { getItemFromStorage } from '../services/storage';

const aioSettings = getItemFromStorage('aio_settings');

const { VITE_BETSLIP_API } = import.meta.env;

const baseUrl = aioSettings?.betslipApi ?? VITE_BETSLIP_API;

const http = axios.create({
  baseURL: `${baseUrl}/api/v1`,
});

export async function placeBet(payload) {
  return http({
    method: 'POST',
    url: `/tenants/${window.tenantUuid}/betslips`,
    headers: {
      Authorization: `Bearer ${punterToken || ''}`,
    },
    params: {
      reqUuid: payload.reqUuid,
    },
    data: payload,
  });
}

export async function checkBetslip(requestUuid) {
  return http({
    method: 'GET',
    url: `tenants/${window.tenantUuid}/betslips/requestUuids/${requestUuid}`,
    headers: {
      Authorization: `Bearer ${punterToken || ''}`,
    },
  });
}

export async function acceptAlternativeStakeBet(payload) {
  const data = {
    ...payload.bet,
  };

  return http({
    method: 'POST',
    url: `/tenants/${window.tenantUuid}/betslips/${payload.betslipId}/bets/${payload.betId}/accept-alternative-stake`,
    headers: {
      Authorization: `Bearer ${punterToken || ''}`,
    },
    data,
  });
}
