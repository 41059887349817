import axios from 'axios';

const { VITE_CUSTOM_BET } = import.meta.env;

const http = axios.create({
  baseURL: `${VITE_CUSTOM_BET}/api/v1`,
});

export async function getCustomBets(eventId, language = 'en') {
  try {
    const response = await http({
      method: 'GET',
      url: `/tenants/${window.tenantUuid}/events/${eventId}/selections`,
      params: {
        language,
      },
    });

    return response.data;
  } catch (e) {
    console.log(e);
  }
}

export async function getBetBuilderCalculations(eventId, selections, language = 'en') {
  try {
    const response = await http({
      method: 'POST',
      url: `/tenants/${window.tenantUuid}/events/${eventId}/calculate-selections`,
      params: {
        language,
      },
      data: {
        selections,
      },
    });

    return response.data;
  } catch (e) {
    console.log(e);
  }
}
