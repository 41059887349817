import { v4 as uuid } from 'uuid';
import { createIntegratorChild } from '@nsftx/seven-client-sdk';

const { VITE_PROVIDER_ID } = import.meta.env;

const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));
const isInDebugMode = Boolean(queryParams?.debug);

if (queryParams?.platform === 'seven') {
  const tempUrl = new URL(window.location);
  tempUrl.searchParams.delete('platform');
  window.history.replaceState({}, document.title, tempUrl.toString());
}

function setupStandalone() {
  window.standalone = true;
  if (isInDebugMode) window.debug = true;
  window.tenantUuid = queryParams.tenantId;
  window.languageCode =
    (queryParams?.language === 'sr-Latn' ? 'sr' : queryParams?.language) ?? 'en';
  window.currency = queryParams?.currency?.toUpperCase();
  window.oddsType = queryParams?.oddsType ?? '';
}

export default function createChildIntegratorInstance(events) {
  if (queryParams?.standalone) setupStandalone();

  return createIntegratorChild({
    context: {
      env: appEnvironment,
      debug: isInDebugMode,
      autoResize: true,
    },
    app: {
      id: uuid(),
      version: appVersion,
      displayId: 'sportsbook',
      providerId: Number(VITE_PROVIDER_ID),
    },
    events,
  });
}
