const statusMap = {
  1: 'NS',
  2: 'IP',
  3: 'END',
};

export function mapBiathlonEvent(event) {
  return {
    displayEventStatus: true,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
  };
}
