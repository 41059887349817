const statusMap = {
  1: 'NS',
  2: '1S',
  3: '1B',
  4: '2S',
  5: '2B',
  6: '3S',
  7: 'IP',
  8: 'END',
};

const scorePeriodsMap = {
  1: '1S',
  2: '2S',
  3: '3S',
};

function mapPeriods(periods = []) {
  if (!periods?.length) return [];

  return periods.reduce((score, { id, value: n }) => {
    score.push({ label: scorePeriodsMap[id], scores: [n.home, n.away] });
    return score;
  }, []);
}

export function mapPadelEvent(event) {
  return {
    displayEventStatus: true,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
    periods: mapPeriods(event.events?.find((event) => event.id === 3)?.periods),
    currentScoreInSets: event.events?.find((event) => event.id === 1)?.value ?? {},
    currentScoreInCurrentSet: event.events?.find((event) => event.id === 2)?.value ?? {},
    server: event.events?.find((event) => event.id === 5)?.value ?? {},
  };
}
