export const sirConnect = (clientId = '326ae239e3a0fb8b9034cb6ee2ed9535') => {
  const language =
    {
      'sr-Latn': 'srl',
    }?.[window.languageCode] ?? window.languageCode;

  const sirUrl = `https://widgets.sir.sportradar.com/${clientId}/widgetloader`;

  try {
    (function (a, b, c, d, e, f, g, h, i) {
      a[e] ||
        ((i = a[e] =
          function () {
            (a[e].q = a[e].q || []).push(arguments);
          }),
        (i.l = 1 * new Date()),
        (i.o = f),
        (g = b.createElement(c)),
        (h = b.getElementsByTagName(c)[0]),
        (g.async = 1),
        (g.src = d),
        g.setAttribute('n', e),
        h.parentNode.insertBefore(g, h));
    })(window, document, 'script', sirUrl, 'SIR', {
      theme: false,
      language,
    });
  } catch (error) {
    console.log(`We've encountered error loading SIR script.`, error);
  }
};
