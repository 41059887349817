import axios from 'axios';

const { VITE_PUNTER_PREFERENCES } = import.meta.env;

const http = axios.create({
  baseURL: `${VITE_PUNTER_PREFERENCES}/punter`,
});

export async function getPunterPreferences(punterId = '') {
  try {
    const response = await http({
      method: 'GET',
      url: punterId,
      headers: {
        Authorization: `Bearer ${window.punterToken}`,
      },
    });

    return response.data;
  } catch (error) {
    return null;
  }
}

export async function setPunterPreferences(punterId = '', preferences) {
  try {
    const response = await http({
      method: 'POST',
      url: punterId,
      data: preferences,
      headers: {
        Authorization: `Bearer ${window.punterToken}`,
      },
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}
