import { mergeWith } from 'lodash-es';
import defaultConfig from '../config/default';

export default async function getLocalConfig(tenantName) {
  const appConfig = defaultConfig;

  try {
    const { default: tenantConfig } = await import(`../config/${tenantName}.js`);
    return mergeWith(appConfig, tenantConfig || {}, (_objValue, srcValue) => {
      if (Array.isArray(srcValue)) return srcValue;
    });
  } catch (error) {
    console.log(
      `We've encountered error loading config for ${tenantName}. Proceeding with default config.`,
      error,
    );

    return appConfig;
  }
}
