export default function useSlideTransition() {
  const beforeEnter = (el) => {
    el.style.height = '0';
    el.style.opacity = '0';
  };

  const enter = (el) => {
    el.style.height = `${el.scrollHeight}px`;
    el.style.opacity = '1';
  };

  const beforeLeave = (el) => {
    el.style.height = `${el.scrollHeight}px`;
    el.style.opacity = '1';
  };

  const leave = (el) => {
    el.style.height = '0';
    el.style.opacity = '0';
  };

  const afterEnter = (el) => {
    el.style.height = 'auto';
  };

  return {
    leave,
    enter,
    afterEnter,
    beforeEnter,
    beforeLeave,
  };
}
