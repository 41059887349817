export const stagingTenants = {
  '2cc8583f-bee1-42cb-afc0-1f7063696a83': 'Sportsbook',
  '5997b951-c503-412f-91fb-c5226c8b767e': 'b2btest',
  '0c17fe55-315e-4ee0-b4f6-1364c8557dd1': 'WombBigi',
  '89b6ea63-48a1-48e0-aa65-68e53113e3eb': 'Wayyy',
  '8c81ee12-ccc9-41fd-8395-f76250c21933': 'IDNSports',
};

export const productionTenants = {
  '99549254-1525-413c-8db2-d9652a777bd6': 'NSoft',
  '5556ccf6-ea0c-46a5-a777-e532237012de': 'Showcase',
  'c78c8828-a621-4a6c-9e77-1a5ba8a72da8': 'Kingroyal',
  'e8ba2d2c-d3a4-4f8f-a6ac-abd817c9628a': 'Meritking',
  '8c5c6f69-48f8-436c-8b7e-8fcc6af0d93d': 'Wayyy',
  '594d3341-c68e-46d0-9a63-03f41e9003c9': 'IDNSports',
};

export default {
  ...stagingTenants,
  ...productionTenants,
};
