const statusMap = {
  1: 'NS',
  2: '1P',
  3: 'HT',
  4: '2P',
  5: 'END',
  6: 'A. OT',
  7: '1P.OT',
  8: 'HT.OT',
  9: '2P.OT',
  10: 'END.A.OT',
  11: 'A.PT',
  12: 'PT',
  13: 'END.A.PT',
};

const scorePeriodsMap = {
  1: '1P',
  2: '2P',
  3: 'OT',
  4: '1P.OT',
  5: '2P.OT',
  6: 'PT',
};

function mapIceHockeyPeriods(periods = []) {
  if (!periods?.length) return [];

  return periods.reduce((score, { id, value: n }) => {
    score.push({ label: scorePeriodsMap[id], scores: [n.home, n.away] });
    return score;
  }, []);
}

export function mapHandballEvent(event) {
  return {
    displayEventStatus: true,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
    periods: mapIceHockeyPeriods(event.events.find((event) => event.id === 3)?.periods),
    currentGameScore: event.events.find((event) => event.id === 1)?.value ?? null,
    eventTime: event.events.find((event) => event.id === 6)?.value?.value || 0,
  };
}
