import axios from 'axios';

const DEV_MODE = import.meta.env.DEV;

/**
 * Fetches translations for a specific language and tenant.
 * Domain name: sports-client-aio-web
 *
 * @async
 * @function getTranslations
 * @returns {Promise<Object>} The translation data retrieved from the API.
 * @throws Will log an error to the console in development mode if the request fails.
 */
export default async function getTranslations() {
  try {
    const url = `${
      import.meta.env.VITE_TRANSLATIONS_API
    }/translations/1121f69a74a56563ac0d0da8740d277b/locales/${window.languageCode}/tenants/${
      window.tenantUuid
    }?includeLocaleFallback=true`;

    const response = await axios({
      method: 'GET',
      url,
    });

    return response.data;
  } catch (err) {
    if (DEV_MODE) console.error(err);
  }
}
