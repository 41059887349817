const statusMap = {
  1: 'NS',
  2: '1M',
  3: '1B',
  4: '2M',
  5: '2B',
  6: '3M',
  7: '3B',
  8: '4M',
  9: '4B',
  10: '5M',
  11: '5B',
  12: '6M',
  13: '6B',
  14: '7M',
  15: 'END',
};

const periodsMap = {
  1: '1M',
  2: '2M',
  3: '3M',
  4: '4M',
  5: '5M',
  6: '6M',
  7: '7M',
};

function mapPeriods(periods = []) {
  if (!periods?.length) return [];

  return periods.reduce((score, { id, value: n }) => {
    score.push({ label: periodsMap[id], scores: [n.home, n.away] });
    return score;
  }, []);
}

export function mapCounterStrikeEvent(event) {
  return {
    displayEventStatus: true,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
    periods: mapPeriods(event.events?.find((event) => event.id === 3)?.periods),
    currentScoreInSets: event.events?.find((event) => event.id === 1)?.value ?? {},
    currentScoreInCurrentSet: event.events?.find((event) => event.id === 2)?.value ?? {},
  };
}
