import { useBreakpoints } from '@vueuse/core';

export default function useBreakpointsCreator() {
  const breakpoints = useBreakpoints({
    xs: 768,
    sm: 1023,
    md: 1279,
    lg: 1439,
    xl: 1679,
    xxl: 1919,
  });

  const smallerThanLg = breakpoints.smaller('lg');
  const smallerThanOrEqualToMd = breakpoints.smallerOrEqual('md');
  const smallerOrEqualThanXl = breakpoints.smaller('xl');
  const betweenLgAndXL = breakpoints.between('lg', 'xl');
  const greaterOrEqualToXxl = breakpoints.greaterOrEqual('xxl');
  const betweenLgAndXxl = breakpoints.between('lg', 'xxl');
  const betweenMdAndLg = breakpoints.between('md', 'lg');
  const betweenSmAndMd = breakpoints.between('sm', 'md');
  const betweenXsAndSm = breakpoints.between('xs', 'sm');

  return {
    breakpoints,
    smallerThanLg,
    smallerOrEqualThanXl,
    smallerThanOrEqualToMd,
    betweenLgAndXL,
    greaterOrEqualToXxl,
    betweenLgAndXxl,
    betweenMdAndLg,
    betweenSmAndMd,
    betweenXsAndSm,
  };
}
