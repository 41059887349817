<template>
  <div class="spinner__container">
    <div class="spinner"></div>
  </div>
</template>

<style lang="scss">
:root {
  --spinner-size: 2.5rem;
  --spinner-border-size: 0.125rem;
}

:root {
  --spinner-bg: hsla(0, 0%, 100%, 20%);
  --spinner-color: var(--text-delta);
}

:root[data-theme='light'] {
  --spinner-bg: hsl(0, 0%, 0%, 20%);
  --spinner-color: #000;
}
</style>

<style lang="scss" scoped>
.spinner__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  @include z-index(overlay);

  &.mini {
    .spinner {
      width: calc(var(--spinner-size) / 2);
      height: calc(var(--spinner-size) / 2);
    }
  }

  .spinner {
    border-radius: 50%;
    width: var(--spinner-size);
    height: var(--spinner-size);
    position: absolute;
    border: var(--spinner-border-size) solid var(--spinner-color);
    border-top: var(--spinner-border-size) solid var(--spinner-bg);
    transform: translateZ(0);
    animation-name: load;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: var(--spinner-size);
      height: var(--spinner-size);
    }
  }
}

@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
