const statusMap = {
  1: 'NS',
  2: 'IP',
  3: 'END',
  4: 'OT',
  5: 'END.OT',
};

const periodsMap = {
  1: 'SCORE',
  2: 'OT',
};

function mapPeriods(eventTypes) {
  const periods =
    eventTypes
      .find((type) => type.id === 3)
      ?.periods?.filter((period) => period.id !== 1 && period.id !== 2) ?? [];

  if (!periods.length) return [];

  return periods.reduce((score, { id, value: n }) => {
    score.push({ label: periodsMap[id], scores: [n.home, n.away] });
    return score;
  }, []);
}

export function mapBasketball3x3Event(event) {
  return {
    displayEventStatus: true,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
    periods: mapPeriods(event.events),
    currentGameScore: event.events.find((event) => event.id === 1)?.value ?? null,
    eventTime: event.events.find((event) => event.id === 4)?.value?.value || 0,
  };
}
